/**
 * Polyfills
 * This file imports polyfills to be used in our application.
 * Adding a polyfill will impact performance so we should add
 * only when absolutely needed.
 * file is imported in next.config.js
 */
// @ts-ignore
if (typeof window !== 'undefined') {
  // Very useful API. Example use case would be to lazy load images as
  // they come into the viewport
  // require('intersection-observer'); leaving here in case we need this while testing
  // Due to Chrome making decisions that everything gains focus state on click
  // we needed a way to make this not happen.
  // More Information: https://css-tricks.com/keyboard-only-focus-styles/
  require('focus-visible');
}
